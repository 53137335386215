import Swiper from "swiper";
import { Autoplay, Pagination } from "swiper/modules";

(() => {
    const frontpageExamplesSlider = document.querySelector('.frontpage-examples-slider');

    new Swiper(frontpageExamplesSlider, {
        modules: [Autoplay, Pagination],
        speed: 1200,
        loop: true,
        autoplay: {
            delay: 4000,
            pauseOnMouseEnter: false,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.frontpage-examples-slider__pagination',
            clickable: true,
        }
    })
})();